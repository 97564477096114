import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BulkContainersService } from '../../../core/services';
import { environment } from '../../../../../../environments/environment';
import { Observable, Subject } from 'rxjs';
import { ISimpleListItem } from '../../../../../core/interfaces/ISimpleListItem';
import { SuppliersService } from '../../../../suppliers/core/services/SuppliersService';
import { NotificationService } from '../../../../../core/services';
import { debounceTime, finalize, switchMap } from 'rxjs/operators';
import * as moment from 'moment/moment';

@Component({
  selector: 'app-new-delivery-modal',
  templateUrl: './new-delivery-modal.component.html',
  styleUrls: ['./new-delivery-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewDeliveryModalComponent implements OnInit {

  @Input() articleId: number;

  @Output() deliveryCreated = new EventEmitter<void>();

  public isSuppliersLoading: boolean;
  public isConfirmLoading = false;
  public isVisible = false;
  public form: FormGroup;
  public isSubmitted = false;
  public isVariant = false;
  public suppliersList: ISimpleListItem[];
  public readonly dateFormat = environment.defaultDateFormat;
  public readonly searchSupplierChange$ = new Subject();

  private _articleId: number;

  public constructor(
    private readonly _cdr: ChangeDetectorRef,
    private readonly _fb: FormBuilder,
    private readonly _bulkContainersService: BulkContainersService,
    private readonly _suppliersService: SuppliersService,
    private readonly _notificationService: NotificationService,
  ) {
    this.form = this._fb.group({
      pricePerKg: [null],
      quantity: ['', Validators.required],
      comment: [''],
      deliveryDate: ['', Validators.required],
      charge: ['', Validators.required],
      mhd: ['', Validators.required],
      supplier: ['', Validators.required],
    });
  }

  public ngOnInit(): void {
    const getSuppliers = (name: string) => this._suppliersService.getSimpleList(name);
    const suppliersOptionList$: Observable<ISimpleListItem[]> = this.searchSupplierChange$
      .asObservable()
      .pipe(debounceTime(500))
      .pipe(switchMap(getSuppliers));

    suppliersOptionList$.subscribe(data => {
      this.suppliersList = data;
      this.isSuppliersLoading = false;
      this._cdr.detectChanges();
    });
  }

  public compareSimpleList(val1: ISimpleListItem, val2: ISimpleListItem): boolean {
    return val1?.id?.toString() === val2?.id?.toString();
  }

  public onSearchSupplier(value: string): void {
    this.isSuppliersLoading = true;
    this.searchSupplierChange$.next(value);
  }

  public show(articleId: number, isVariant: boolean): void {
    this._articleId = articleId;
    this.isVisible = true;
    this.isVariant = isVariant;

    this._cdr.detectChanges();
  }

  public handleOk(): void {
    this.submitForm();
  }

  public handleCancel(): void {
    this.isVisible = false;
    this._cdr.detectChanges();
  }

  public submitForm(): void {
    this.isSubmitted = true;
    this.isConfirmLoading = true;

    for (const key in this.form.controls) {
      if (this.form.controls.hasOwnProperty(key)) {
        this.form.controls[key].markAsDirty();
        this.form.controls[key].updateValueAndValidity();
      }
    }

    if (!this.form.valid) {
      this.isConfirmLoading = false;
      return;
    }

    const body = {
      ...this.form.value,
      deliveryDate: moment(this.form.value['deliveryDate']).format('YYYY-MM-DD'),
      mhd: moment(this.form.value['mhd']).format('YYYY-MM-DD'),
      supplierId: this.form.value.supplier.id,
      quantity: this.form.value.quantity * 1000
    };

    delete body.supplier;

    this.isVariant ? body.articleVariantId = this._articleId : body.articleId = this._articleId;

    this._bulkContainersService.create(body).pipe(
      finalize(() => {
        this.isConfirmLoading = false;
        this._cdr.detectChanges();
      })
    ).subscribe({
      next: () => {
        this._notificationService.pushSuccess('Erfolgreich', 'Lieferung erstellt');
        this.deliveryCreated.emit();
        this.isVisible = false;
        this.form.reset();
      },
      error: () => this._notificationService.pushError('Fehler', 'Fehler beim Erstellen der Lieferung')
    });
  }
}
