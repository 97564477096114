import {
  Component,
  OnInit,
  ChangeDetectionStrategy, ChangeDetectorRef, Input,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { IBulkContainer } from '../../../core/interfaces/IBulkContainer';
import { AddEditFormGeneric } from '../../../../../ui/generics';
import { BulkContainersService, BulkContainersServiceErrorResolverService } from '../../../core/services';
import { NotificationService } from '../../../../../core/services';
import { Observable, Subject } from 'rxjs';
import { ISimpleListItem } from '../../../../../core/interfaces/ISimpleListItem';
import { debounceTime, switchMap } from 'rxjs/operators';
import { SuppliersService } from '../../../../suppliers/core/services/SuppliersService';
import { BulkContainerStatuses } from '../../../../../core/enums';
import * as moment from 'moment';

@Component({
  selector: 'app-edit-form',
  templateUrl: './edit-form.component.html',
  styleUrls: ['./edit-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditFormComponent extends AddEditFormGeneric<IBulkContainer> implements OnInit {

  @Input() isVariant: boolean;

  isSuppliersLoading: boolean;
  suppliersList: ISimpleListItem[];
  searchSupplierChange$ = new Subject();
  public readonly bulkContainerStatuses = BulkContainerStatuses;

  constructor(
    protected _fb: FormBuilder,
    protected _service: BulkContainersService,
    protected _notifyService: NotificationService,
    protected _errorResolver: BulkContainersServiceErrorResolverService,
    private _supplierService: SuppliersService,
    private _cdr: ChangeDetectorRef,
  ) {
    super(_fb, _service, _notifyService, _errorResolver);
    this.form = this._fb.group({
      supplier: ['', Validators.required],
      deliveryDate: ['', Validators.required],
      charge: ['', Validators.required],
      quantity: ['', Validators.required],
      mhd: ['', Validators.required],
      status: ['', Validators.required],
      pricePerKg: [null],
      checked: [false],
    });
  }

  ngOnInit(): void {
    const getSuppliers = (name: string) =>
      this._supplierService.getSimpleList(name);

    const suppliersOptionList$: Observable<ISimpleListItem[]> = this.searchSupplierChange$
      .asObservable()
      .pipe(debounceTime(500))
      .pipe(switchMap(getSuppliers));
    suppliersOptionList$.subscribe(data => {
      this.suppliersList = data;
      this.isSuppliersLoading = false;
      this._cdr.detectChanges();
    });
  }

  protected afterLoadForm(): void {
    if (!this.isVariant) {
      this.form.get('quantity').patchValue((this.editObject.quantity / 1000).toFixed(2));
    }
  }

  onSearchSupplier(value: string) {
    this.isSuppliersLoading = true;
    this.searchSupplierChange$.next(value);
  }

  beforeLoadForm() {
    if (this.editObject.supplier) {
      this.searchSupplierChange$.next(this.editObject.supplier.name);
    }
  }

  saveEdit(): void {
    if (!this.isVariant) {
      this.form.value['quantity'] = this.form.value['quantity'] * 1000;
    }

    this.form.value['mhd'] = moment(this.form.value['mhd']).format('YYYY-MM-DD');
    this.form.value['deliveryDate'] = moment(this.form.value['deliveryDate']).format('YYYY-MM-DD');
    this.form.value['stateDate'] = moment(this.form.value['stateDate']).format('YYYY-MM-DD');

    super.saveEdit();
  }
}
