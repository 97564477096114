import { AfterViewInit, ChangeDetectorRef, Directive, ElementRef, Inject, Input, OnDestroy } from '@angular/core';
import { BulkContainersService } from '../../pages/bulk-containers/core/services';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appChargeInput]',
  host: {
    '(input)': 'onInput()'
  }
})
export class ChargeInputDirective implements AfterViewInit, OnDestroy {

  @Input() initialValue: string;
  @Input() articleId: number;
  @Input() articleVariantId: number;

  private _errorTextEl: HTMLSpanElement;
  private readonly _charge$ = new Subject<string>();
  private readonly _sub: Subscription;

  public constructor(
    private readonly _bulkContainersService: BulkContainersService,
    private readonly _el: ElementRef<HTMLInputElement>,
    private readonly _ngControl: NgControl,
    private readonly _cdr: ChangeDetectorRef,
    @Inject(DOCUMENT) private readonly _document: Document,
  ) {
    const chargeValidator = () => ({ chargeInvalid: true });
    const removeErrorMessage = () => {
      this._el.nativeElement.classList.remove('charge-invalid');
      this._errorTextEl.classList.replace('d-block', 'd-none');
      this._ngControl.control.removeValidators(chargeValidator);
      this._ngControl.control.updateValueAndValidity();
    };

    if (!this._el.nativeElement.hidden) {
      this._sub = this._charge$.pipe(
        debounceTime(500)
      ).subscribe(() => {
        const val = this._el.nativeElement.value;

        if (val === this.initialValue || !this.articleId) {
          removeErrorMessage();
          return;
        }

        if (val.length === 0) {
          removeErrorMessage();
        } else {
          this._bulkContainersService.checkCharge(
            val,
            this.articleId,
            this.articleVariantId
          ).subscribe(res => {
            if (res.isValid) {
              removeErrorMessage();
            } else {
              this._el.nativeElement.classList.add('charge-invalid');
              this._errorTextEl.classList.replace('d-none', 'd-block');
              this._ngControl.control.addValidators(chargeValidator);
              this._ngControl.control.updateValueAndValidity();
            }
          });
        }
      });
    }
  }

  public ngAfterViewInit(): void {
    this._errorTextEl = this._document.createElement('span');
    this._errorTextEl.classList.add('ant-form-item-explain-error', 'charge-exists-error', 'd-none');
    this._errorTextEl.textContent = 'Charge already exists!';
    this._el.nativeElement.parentElement.appendChild(this._errorTextEl);
  }

  public onInput(): void {
    this._charge$.next();
  }

  public ngOnDestroy(): void {
    this._sub?.unsubscribe();
  }
}
