<app-table-header
  (reload)="onRefreshList(false)"
  [loading]="loading"
  [withExportOption]="!!data.info.exportTableName"
  (exportBtnClicked)="exportBtnClicked()"
>
  <button
    nz-button
    nzType="primary"
    *ngIf="!isVariant"
    style="margin-right: auto;"
    (click)="newDeliveryModalComponent.show(articleId, isVariant)"
  >New delivery</button>
</app-table-header>
<nz-table
  #tableRef
  [nzScroll]="{ x: tableMaxWidth }"
  nzShowSizeChanger
  [nzData]="data.results"
  [nzLoading]="loading"
  [nzTotal]="data.info.totalResults"
  [nzFrontPagination]="false"
  [nzPageSize]="pageSize"
  [nzPageIndex]="data.info.page"
  (nzQueryParams)="onQueryParamsChange($event)"
  appKeepState
  id="deliveries-list-table"
>
  <thead>
  <tr>
    <th nzColumnKey="id" [nzSortFn]="true" nzWidth="75px" nzSortOrder="descend">#ID</th>
    <th nzColumnKey="deliveryDate" [nzSortFn]="true">Lieferdatum</th>
    <th nzColumnKey="supplier.name" [nzSortFn]="true">Lieferant</th>
    <th nzColumnKey="mhd" [nzSortFn]="true">MHD</th>
    <th nzColumnKey="charge" [nzSortFn]="true">Charge</th>
    <th nzColumnKey="quantity" [nzSortFn]="true">Menge</th>
    <th nzColumnKey="state.value" [nzSortFn]="true" *ngIf="!isVariant">Restmenge</th>
    <th nzColumnKey="pricePerKg">
      {{ !isVariant ? 'Preis netto pro Kilo' : 'Preis netto pro Stück' }}
    </th>
    <th nzColumnKey="price" [nzSortFn]="true">Preis netto</th>
    <th nzColumnKey="checked" [nzSortFn]="true">Wareneingang geprüft</th>
    <th nzColumnKey="status" [nzSortFn]="true" *ngIf="!isVariant">Status</th>
    <th nzRight></th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let delivery of data.results">
    <td>#{{delivery.id}}</td>
    <td>{{delivery.deliveryDate|dateFormat}}</td>
    <td>{{delivery.supplier.name}}</td>
    <td>{{delivery.mhd|dateFormat}}</td>
    <td>{{delivery.charge}}</td>
    <td>{{ delivery.quantity | productionUnit:!isVariant }}</td>
    <td *ngIf="!isVariant">{{ delivery.state.value | productionUnit:true }}</td>
    <td>{{ delivery.pricePerKg ? (delivery.pricePerKg | number:'1.2-2') + ' €' : '' }}</td>
    <td>{{ delivery.price ? delivery.price + ' €' : '' }}</td>
    <td>{{delivery.checked | booleanFormat}}</td>
    <td *ngIf="!isVariant">{{delivery.status | statusFormat}}</td>
    <td nzRight>
      <div class="actions">
        <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
          <i nz-icon nzType="more" nzTheme="outline"></i>
        </a>
        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu>
            <ng-container *ngIf="!isVariant">
              <li nz-menu-item (click)="newProductionOrderModal.showModalForBulkContainer(delivery)">
                Neue Produktionsauftrag
              </li>
              <li nz-menu-divider></li>
              <li nz-menu-item (click)="correctModal.show(delivery.id)">Inventurkorrektur</li>
            </ng-container>
            <li nz-menu-item (click)="modal.showModal(delivery)">
              <app-edit-modal (refresh)="onRefreshList(); deliveryEdited.emit()" #modal [isVariant]="isVariant">
                <span trigger>Bearbeiten</span>
              </app-edit-modal>
            </li>
            <li nz-menu-item *ngIf="delivery.ownProductionDocumentKey">
              <app-download-document
                class="full-width"
                [documentKey]="delivery.ownProductionDocumentKey"
                text="Protokoll"
              ></app-download-document>
            </li>
            <li nz-menu-item (click)="cancelBulkContainer(delivery.id)">Löschen</li>
          </ul>
        </nz-dropdown-menu>
      </div>
    </td>
  </tr>
  </tbody>
</nz-table>
<app-correct-quantity-modal
  #correctModal
  (changeLogCreated)="onRefreshList(false); changeLogCreated.emit();"
></app-correct-quantity-modal>
<app-add-edit-modal
  #newProductionOrderModal
  (refresh)="onRefreshList(false); newProductionOrderCreated.emit()"
>
  <span trigger></span>
</app-add-edit-modal>
<app-new-delivery-modal
  [articleId]="articleId"
  #newDeliveryModalComponent
  (deliveryCreated)="onRefreshList(false); deliveryCreated.emit()"
></app-new-delivery-modal>

<app-export-table-modal #exportTableModalComponent></app-export-table-modal>
